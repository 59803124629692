import apiOrganizations from '@services/api/manager/organizations'

export default {
  namespaced: true,
  actions: {
    getPanel(_, payload) {
      return apiOrganizations.panel(this.$http, payload)
    },
  },
}
