import { asyncLoadRouter } from '@router/helper'
import property from '@router/paths/manager/machines/property'
import telemetry from '@router/paths/manager/machines/telemetry'
import analytics from '@router/paths/manager/machines/analytics'
import firmware from '@router/paths/manager/machines/firmware'
import reports from '@router/paths/manager/machines/reports'

const machines = [
  {
    path: 'machines',
    component: asyncLoadRouter('manager/Machines'),
    meta: {
      authorize: ['admin', 'manager', 'technician'],
      breadcrumb: (i18n) => [
        {
          text: i18n.t('activerecord.models.machine.other'),
          to: { name: 'manager-machines' },
        },
      ],
    },
    children: [
      {
        path: '',
        name: 'manager-machines',
        component: asyncLoadRouter('manager/machines/List'),
        meta: { breadcrumb: (i18n) => [{ text: i18n.t('shared.actions.list') }] },
      },
      {
        path: 'new',
        name: 'manager-machines-new',
        component: asyncLoadRouter('manager/machines/New'),
        meta: {
          breadcrumb: (i18n) => [
            {
              text: i18n.t('shared.actions.new'),
              to: { name: 'manager-machines-new' },
            },
          ],
        },
        props: { action: 'create' },
      },
      {
        path: ':machine_id([A-Za-z\\d]{22})',
        name: 'manager-machines-show',
        component: asyncLoadRouter('manager/machines/Show'),
        redirect: (to) => {
          return {
            name: 'manager-machines-edit',
            params: { machine_id: to.params.machine_id },
          }
        },
        meta: {
          breadcrumb: (i18n, params) => [
            {
              text: `ID ${params.machine_id}`,
              to: {
                name: 'manager-machines-edit',
                params: { machine_id: params.machine_id },
              },
            },
          ],
        },
        children: [
          {
            path: 'edit',
            name: 'manager-machines-edit',
            component: asyncLoadRouter('manager/machines/Form'),
            meta: { breadcrumb: (i18n) => [{ text: i18n.t('shared.actions.edit') }] },
            props: { action: 'update' },
          },
          ...property,
          ...telemetry,
          ...analytics,
          ...firmware,
          ...reports,
        ],
      },
    ],
  },
]

export default machines
