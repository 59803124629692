import Vue from 'vue'
import VueGtag from 'vue-gtag'

/**
 * @param {object} wc A config instance.
 * @param {object} router A router instance.
 */
export default (wc, router) => {
  Vue.use(
    VueGtag,
    {
      appName: `${wc.conf.base.prefix}${wc.conf.env.short}`,
      pageTrackerScreenviewEnabled: true,
      bootstrap: false,
      /* ATTENTION: Don't remove this excluded tracking pages for security and data protection reasons. */
      pageTrackerExcludedRoutes: ['oauth-callback', 'oauth-callback-silent'],
    },
    router
  )
}
