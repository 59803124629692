import { asyncLoadRouter } from '@router/helper'
import account from '@router/paths/manager/account'
import identities from '@router/paths/manager/identities'
import accounts from '@router/paths/manager/accounts'
import organizations from '@router/paths/manager/organizations'
import machines from '@router/paths/manager/machines'
import firmwares from '@router/paths/manager/firmwares'
import categories from '@router/paths/manager/globals/categories'
import genders from '@router/paths/manager/globals/genders'
import link_networks from '@router/paths/manager/globals/contacts/link_networks'
import models from '@router/paths/manager/globals/machines/models'

const manager = [
  {
    path: 'manager',
    component: asyncLoadRouter('Manager'),
    meta: {
      authorize: ['admin', 'manager', 'technician'],
    },
    children: [
      {
        path: '',
        name: 'manager',
        component: asyncLoadRouter('manager/Dashboard'),
        meta: {
          breadcrumb: (i18n) => [
            { text: i18n.t('shared.menus.dashboard'), to: { name: 'manager' } },
            { text: i18n.t('shared.actions.show') },
          ],
        },
      },
      ...account,
      ...identities,
      ...accounts,
      ...organizations,
      ...machines,
      ...firmwares,
      ...categories,
      ...genders,
      ...link_networks,
      ...models,
    ],
  },
]

export default manager
