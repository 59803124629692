import { asyncLoadRouter } from '@router/helper'

const organization = [
  {
    path: 'organization',
    component: asyncLoadRouter('Account'),
    children: [
      {
        path: '',
        name: 'organization-show',
        component: asyncLoadRouter('organization/Show'),
        redirect: () => {
          return { name: 'organization-edit' }
        },
        meta: {
          breadcrumb: (i18n) => [
            {
              text: i18n.t('organization.menus.my_organization'),
              to: { name: 'organization-edit' },
            },
          ],
        },
        children: [
          {
            path: 'edit',
            name: 'organization-edit',
            component: asyncLoadRouter('organization/Form'),
            meta: { breadcrumb: (i18n) => [{ text: i18n.t('shared.actions.edit') }] },
            props: { action: 'update' },
          },
        ],
      },
      {
        path: 'profile',
        component: asyncLoadRouter('organization/Profile'),
        redirect: () => {
          return { name: 'organization-profile-edit' }
        },
        meta: {
          breadcrumb: (i18n) => [
            {
              text: i18n.t('organization/profile.menus.my_profile'),
              to: { name: 'organization-profile-edit' },
            },
          ],
        },
        children: [
          {
            path: 'edit',
            name: 'organization-profile-edit',
            component: asyncLoadRouter('organization/profile/Form'),
            meta: { breadcrumb: (i18n) => [{ text: i18n.t('shared.actions.edit') }] },
            props: { action: 'update' },
          },
        ],
      },
      {
        path: 'contact',
        component: asyncLoadRouter('organization/Contact'),
        redirect: () => {
          return { name: 'organization-contact-edit' }
        },
        meta: {
          breadcrumb: (i18n) => [
            {
              text: i18n.t('contact.menus.my_contact'),
              to: { name: 'organization-contact-edit' },
            },
          ],
        },
        children: [
          {
            path: 'edit',
            name: 'organization-contact-edit',
            component: asyncLoadRouter('organization/contact/Form'),
            meta: { breadcrumb: (i18n) => [{ text: i18n.t('shared.actions.edit') }] },
            props: { action: 'update' },
          },
        ],
      },
      {
        path: 'address',
        component: asyncLoadRouter('organization/Address'),
        redirect: () => {
          return { name: 'organization-address-edit' }
        },
        meta: {
          breadcrumb: (i18n) => [
            {
              text: i18n.t('address.menus.my_address'),
              to: { name: 'organization-address-edit' },
            },
          ],
        },
        children: [
          {
            path: 'edit',
            name: 'organization-address-edit',
            component: asyncLoadRouter('organization/address/Form'),
            meta: { breadcrumb: (i18n) => [{ text: i18n.t('shared.actions.edit') }] },
            props: { action: 'update' },
          },
        ],
      },
    ],
  },
]

export default organization
