export default class Organization {
  static from(data) {
    try {
      return new Organization(data)
    } catch (_) {
      return null
    }
  }
  constructor(data) {
    this.sub = data.id
    this.typ = data.type
    this.sid = data.attributes.sid
    this.sta = data.attributes.state
    this.rol = data.attributes.roles_account
    this.pol = data.attributes.policies
  }
}
