import { asyncLoadRouter } from '@router/helper'

const identities = [
  {
    path: 'identities',
    component: asyncLoadRouter('manager/Identities'),
    meta: {
      authorize: ['admin'],
      breadcrumb: (i18n) => [
        { text: i18n.t('activerecord.models.identity.other'), to: { name: 'manager-identities' } },
      ],
    },
    children: [
      {
        path: '',
        name: 'manager-identities',
        component: asyncLoadRouter('manager/identities/List'),
        meta: { breadcrumb: (i18n) => [{ text: i18n.t('shared.actions.list') }] },
      },
      {
        path: ':identity_id([A-Za-z\\d]{22})',
        name: 'manager-identities-show',
        component: asyncLoadRouter('manager/identities/Show'),
        redirect: (to) => {
          return {
            name: 'manager-identities-edit',
            params: { identity_id: to.params.identity_id },
          }
        },
        meta: {
          breadcrumb: (i18n, params) => [
            {
              text: `ID ${params.identity_id}`,
              to: {
                name: 'manager-identities-edit',
                params: { identity_id: params.identity_id },
              },
            },
          ],
        },
        children: [
          {
            path: 'edit',
            name: 'manager-identities-edit',
            component: asyncLoadRouter('manager/identities/Form'),
            meta: { breadcrumb: (i18n) => [{ text: i18n.t('shared.actions.edit') }] },
            props: { action: 'update' },
          },
        ],
      },
    ],
  },
]

export default identities
