import { asyncLoadRouter } from '@router/helper'

const statically = [
  {
    path: 'about',
    component: asyncLoadRouter('Statically'),
    children: [
      {
        path: '',
        name: 'about',
        component: asyncLoadRouter('statically/About'),
        meta: {
          breadcrumb: (i18n) => [
            { text: i18n.t('shared.menus.about'), to: { name: 'about' } },
            { text: i18n.t('shared.actions.show') },
          ],
        },
      },
    ],
  },
  {
    path: 'privacy',
    component: asyncLoadRouter('Statically'),
    children: [
      {
        path: '',
        name: 'privacy',
        component: asyncLoadRouter('statically/Privacy'),
        meta: {
          breadcrumb: (i18n) => [
            { text: i18n.t('shared.menus.privacy'), to: { name: 'privacy' } },
            { text: i18n.t('shared.actions.show') },
          ],
        },
      },
    ],
  },
  {
    path: 'terms',
    component: asyncLoadRouter('Statically'),
    children: [
      {
        path: '',
        name: 'terms',
        component: asyncLoadRouter('statically/Terms'),
        meta: {
          breadcrumb: (i18n) => [
            { text: i18n.t('shared.menus.terms'), to: { name: 'terms' } },
            { text: i18n.t('shared.actions.show') },
          ],
        },
      },
    ],
  },
]

export default statically
