import CookieStorage from '@common/storage/cookieStorage'

export default {
  async beforeCreate(context, inject, vueOptions) {
    const { $http, req, store } = context
    const { wc } = vueOptions
    await wc.load($http)

    /* Add shared informations between client and server. */
    wc.shared.locale =
      (req
        ? req.cookies[`_${wc.conf.base.prefix}${wc.conf.env.short}_locale`]
        : CookieStorage.getItem(`_${wc.conf.base.prefix}${wc.conf.env.short}_locale`)) || null

    wc.shared.organization =
      (req
        ? req.cookies[`_${wc.conf.base.prefix}${wc.conf.env.short}_org`]
        : CookieStorage.getItem(`_${wc.conf.base.prefix}${wc.conf.env.short}_org`)) || null

    /* Initialize store. */
    if (req) await store.dispatch('auth/initCurrentOrganization', wc.shared.organization)

    inject('wc', wc)
  },
}
