import api from '@services/api'

export default {
  list(axios, params, query, config) {
    return api.get(axios, '/machines', params, query, config)
  },

  get(axios, params, query, config) {
    return api.get(axios, '/machines/:id', params, query, config)
  },

  panel(axios, params, query, config) {
    return api.get(axios, '/machines/:id/panel', params, query, config)
  },
}
