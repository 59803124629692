import { asyncLoadRouter } from '@router/helper'

const organizations = [
  {
    path: 'organizations',
    component: asyncLoadRouter('Organizations'),
    meta: {
      breadcrumb: (i18n) => [
        {
          text: i18n.t('activerecord.models.organization.other'),
          to: { name: 'organizations' },
        },
      ],
    },
    children: [
      {
        path: '',
        name: 'organizations',
        component: asyncLoadRouter('organizations/List'),
        meta: { breadcrumb: (i18n) => [{ text: i18n.t('shared.actions.list') }] },
      },
    ],
  },
]

export default organizations
