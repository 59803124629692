import apiBase from '@services/api/base'

export default {
  namespaced: true,
  actions: {
    getPanelOrganizations(_, payload) {
      return apiBase.panelOrganizations(this.$http, payload)
    },
    getPanelMachines(_, payload) {
      return apiBase.panelMachines(this.$http, payload)
    },
  },
}
