import apiOrganization from '@services/api/organization'

export default {
  namespaced: true,
  actions: {
    getPanel(_, payload) {
      return apiOrganization.panel(this.$http, payload)
    },
  },
}
