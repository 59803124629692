import apiReport from '@services/api/machines/analytics/report'

/**
 * @param {object} payload An object with payload parameters.
 * @returns {object} An object with only query parameters.
 */
function query(payload) {
  const query = Object.assign({}, payload)
  delete query.machine_id
  return query
}

export default {
  namespaced: true,
  actions: {
    getPanel(_, payload) {
      return apiReport.get(this.$http, payload, query(payload))
    },

    getPanelMachine(_, payload) {
      return apiReport.panelMachine(this.$http, payload, query(payload))
    },

    getPanelBatteries(_, payload) {
      return apiReport.panelBatteries(this.$http, payload, query(payload))
    },

    getPanelTemperatures(_, payload) {
      return apiReport.panelTemperatures(this.$http, payload, query(payload))
    },

    getPanelOperatingHours(_, payload) {
      return apiReport.panelOperatingHours(this.$http, payload, query(payload))
    },

    getPanelTilt(_, payload) {
      return apiReport.panelTilt(this.$http, payload, query(payload))
    },

    getPanelMotorsPercentage(_, payload) {
      return apiReport.panelMotorsPercentage(this.$http, payload, query(payload))
    },

    getPanelMotorsVoltage(_, payload) {
      return apiReport.panelMotorsVoltage(this.$http, payload, query(payload))
    },

    getPanelMotorsCurrent(_, payload) {
      return apiReport.panelMotorsCurrent(this.$http, payload, query(payload))
    },

    getPanelMap(_, payload) {
      return apiReport.panelMap(this.$http, payload, query(payload))
    },
  },
}
