import apiBase from '@services/api/manager/base'

export default {
  namespaced: true,
  actions: {
    getPanelAccounts(_, payload) {
      return apiBase.panelAccounts(this.$http, payload)
    },
    getPanelOrganizations(_, payload) {
      return apiBase.panelOrganizations(this.$http, payload)
    },
    getPanelMachines(_, payload) {
      return apiBase.panelMachines(this.$http, payload)
    },
    getPanelFirmwares(_, payload) {
      return apiBase.panelFirmwares(this.$http, payload)
    },
    getPanelMachinesOnline(_, payload) {
      return apiBase.panelMachinesOnline(this.$http, payload)
    },
  },
}
