import CookieStorage from '@common/storage/cookieStorage'
import Account from '@common/security/account'
import Organization from '@common/security/organization'
import apiAccount from '@services/api/account'
import apiOrganization from '@services/api/organization'

export default (wc) => {
  return {
    namespaced: true,
    state: () => ({
      account: null,
      organization: wc.shared.organization || null,
    }),
    getters: {
      currentAccount: (state) => state.account || {},
      currentAccountPolicies: (state) => (state.account ? state.account.pol : {}),
      currentOrganization: (state) => state.organization || {},
      currentOrganizationPolicies: (state) => (state.organization ? state.organization.pol : {}),
      currentModelType: (state) => (state.organization ? 'organization' : 'account'),
      currentModelSid: (state) =>
        state.organization ? state.organization.sid : state.account ? state.account.sid : null,
      isAuthenticated: (state) => !!state.account,
      isOrganization: (state) => !!state.organization,
      isAdmin: (state) => state.account && state.account.rol.includes('admin'),
      isManager: (state) => state.account && state.account.rol.includes('manager'),
      isAM: (state) =>
        state.account && state.account.rol.some((v) => ['admin', 'manager'].includes(v)),
      isAMT: (state) =>
        state.account &&
        state.account.rol.some((v) => ['admin', 'manager', 'technician'].includes(v)),
      isTechnician: (state) => state.account && state.account.rol.includes('technician'),
      isSubscriber: (state) => state.account && state.account.rol.includes('subscriber'),
      hasOrgs: (state) =>
        state.account &&
        state.account.rol.some((v) =>
          [
            'admin_scp:organization:',
            'manager_scp:organization:',
            'editor_scp:organization:',
          ].includes(v)
        ),
    },
    mutations: {
      initCurrentOrganization(state, data) {
        state.organization = Organization.from(data)
      },
      setCurrentAccount(state, data) {
        state.account = Account.from(data)
        this.$dayjs.tz.setDefault(state.account.tiz)
      },
      setCurrentOrganization(state, data) {
        state.organization = Organization.from(data)
        wc.shared.organization = state.organization.sid
      },
      removeCurrentAccount(state) {
        state.account = null
        CookieStorage.removeItem(`_${wc.conf.base.prefix}${wc.conf.env.short}_token`)
      },
      removeCurrentOrganization(state) {
        wc.shared.organization = null
        state.organization = null
        CookieStorage.removeItem(`_${wc.conf.base.prefix}${wc.conf.env.short}_org`)
      },
    },
    actions: {
      initCurrentOrganization({ commit }, data) {
        if (data) {
          return apiOrganization
            .userinfo(this.$http)
            .promise.then((response) => {
              commit('initCurrentOrganization', response.data)
              return response
            })
            .catch((error) => {
              return error
            })
        } else {
          return Promise.resolve()
        }
      },
      setCurrentAccount({ commit }) {
        return apiAccount
          .userinfo(this.$http)
          .promise.then((response) => {
            commit('setCurrentAccount', response.data)
            return response
          })
          .catch((error) => {
            return error
          })
      },
      setCurrentOrganization({ commit }, data) {
        if (data) {
          CookieStorage.setItem(`_${wc.conf.base.prefix}${wc.conf.env.short}_org`, data, {
            expires: this.$dayjs().add(1, 'year').toDate(),
          })
          return apiOrganization
            .userinfo(this.$http)
            .promise.then((response) => {
              commit('setCurrentOrganization', response.data)
              return response
            })
            .catch((error) => {
              return error
            })
        } else {
          return Promise.resolve()
        }
      },
      removeCurrentAccount({ commit }) {
        this.cache.clear('account/getPanel', {
          account_id: this.getters['auth/currentAccount'].sub,
        })
        commit('removeCurrentAccount')
      },
      removeCurrentOrganization({ commit }) {
        this.cache.clear('organization/getPanel', {
          organization_id: this.getters['auth/currentOrganization'].sub,
        })
        commit('removeCurrentOrganization')
      },
    },
  }
}
