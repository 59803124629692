import { asyncLoadRouter } from '@router/helper'

const roles = [
  {
    path: 'roles',
    component: asyncLoadRouter('manager/accounts/Roles'),
    redirect: (to) => {
      return {
        name: 'manager-accounts-roles-edit',
        params: { account_id: to.params.account_id },
      }
    },
    meta: {
      breadcrumb: (i18n, params) => [
        {
          text: i18n.t('activerecord.models.role.other'),
          to: {
            name: 'manager-accounts-roles-edit',
            params: { account_id: params.account_id },
          },
        },
      ],
    },
    children: [
      {
        path: 'edit',
        name: 'manager-accounts-roles-edit',
        component: asyncLoadRouter('manager/accounts/roles/Form'),
        meta: { breadcrumb: (i18n) => [{ text: i18n.t('shared.actions.edit') }] },
        props: { action: 'update' },
      },
    ],
  },
]

export default roles
