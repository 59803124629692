import { asyncLoadRouter } from '@router/helper'

const machines = [
  {
    path: 'machines',
    component: asyncLoadRouter('manager/organizations/Machines'),
    meta: {
      breadcrumb: (i18n) => [
        {
          text: i18n.t('activerecord.models.machine.other'),
          to: { name: 'manager-organizations-machines' },
        },
      ],
    },
    children: [
      {
        path: '',
        name: 'manager-organizations-machines',
        component: asyncLoadRouter('manager/organizations/machines/List'),
        meta: { breadcrumb: (i18n) => [{ text: i18n.t('shared.actions.list') }] },
      },
    ],
  },
]

export default machines
