import api from '@services/api'

export default {
  list(axios, params, query, config) {
    return api.get(axios, '/manager/identities', params, query, config)
  },

  get(axios, params, query, config) {
    return api.get(axios, '/manager/identities/:id', params, query, config)
  },

  update(axios, params, payload, config) {
    return api.update(axios, '/manager/identities/:id', params, payload, config)
  },

  destroy(axios, params, config) {
    return api.delete(axios, '/manager/identities/:id', params, config)
  },

  panel(axios, params, query, config) {
    return api.get(axios, '/manager/identities/:id/panel', params, query, config)
  },

  uniqueUsername(axios, params, query, config) {
    return api.get(axios, '/manager/identities/unique_username', params, query, config)
  },
}
