import { asyncLoadRouter } from '@router/helper'

const genders = [
  {
    path: 'genders',
    component: asyncLoadRouter('manager/globals/Genders'),
    meta: {
      authorize: ['admin'],
      breadcrumb: (i18n) => [
        {
          text: i18n.t('activerecord.models.gender.other'),
          to: { name: 'manager-genders' },
        },
      ],
    },
    children: [
      {
        path: '',
        name: 'manager-genders',
        component: asyncLoadRouter('manager/globals/genders/List'),
        meta: { breadcrumb: (i18n) => [{ text: i18n.t('shared.actions.list') }] },
      },
      {
        path: 'new',
        name: 'manager-genders-new',
        component: asyncLoadRouter('manager/globals/genders/New'),
        meta: {
          breadcrumb: (i18n) => [
            {
              text: i18n.t('shared.actions.new'),
              to: { name: 'manager-genders-new' },
            },
          ],
        },
        props: { action: 'create' },
      },
      {
        path: ':gender_id([A-Za-z\\d]{22})',
        name: 'manager-genders-show',
        component: asyncLoadRouter('manager/globals/genders/Show'),
        redirect: (to) => {
          return {
            name: 'manager-genders-edit',
            params: { gender_id: to.params.gender_id },
          }
        },
        meta: {
          breadcrumb: (i18n, params) => [
            {
              text: `ID ${params.gender_id}`,
              to: {
                name: 'manager-genders-edit',
                params: { gender_id: params.gender_id },
              },
            },
          ],
        },
        children: [
          {
            path: 'edit',
            name: 'manager-genders-edit',
            component: asyncLoadRouter('manager/globals/genders/Form'),
            meta: { breadcrumb: (i18n) => [{ text: i18n.t('shared.actions.edit') }] },
            props: { action: 'update' },
          },
        ],
      },
    ],
  },
]

export default genders
