import Cookies from 'js-cookie'

const options = {
  sameSite: 'Strict',
  secure: process.env.VUE_APP_WC_CORE_WEB_URL.startsWith('https://') ? true : false,
  domain: process.env.VUE_APP_WC_CORE_COOKIE_DOMAIN,
  path: '/',
}

export default class CookieStorage {
  static setItem(key, value, opts = {}) {
    Cookies.set(key, value, { ...options, ...opts })
  }

  static getItem(key) {
    return Cookies.get(key, options)
  }

  static removeItem(key) {
    Cookies.remove(key, options)
  }

  static key(index) {
    let allKeys = Object.keys(Cookies.getJSON())

    return index > -1 && index <= allKeys.length ? allKeys[index] : ''
  }
}
