import { asyncLoadRouter } from '@router/helper'

const reports = [
  {
    path: 'reports',
    component: asyncLoadRouter('machines/Reports'),
    meta: {
      breadcrumb: (i18n) => [
        {
          text: i18n.t('activerecord.models.machine/report.other'),
          to: { name: 'machines-reports' },
        },
      ],
    },
    children: [
      {
        path: '',
        name: 'machines-reports',
        component: asyncLoadRouter('machines/reports/List'),
        meta: { breadcrumb: (i18n) => [{ text: i18n.t('shared.actions.list') }] },
      },
    ],
  },
]

export default reports
