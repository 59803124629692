import { asyncLoadRouter } from '@router/helper'

const firmware = [
  {
    path: 'firmware',
    component: asyncLoadRouter('manager/machines/Firmware'),
    redirect: (to) => {
      return {
        name: 'manager-machines-firmware-edit',
        params: { machine_id: to.params.machine_id },
      }
    },
    meta: {
      breadcrumb: (i18n, params) => [
        {
          text: i18n.t('activerecord.models.firmware.one'),
          to: {
            name: 'manager-machines-firmware-edit',
            params: { machine_id: params.machine_id },
          },
        },
      ],
    },
    children: [
      {
        path: 'edit',
        name: 'manager-machines-firmware-edit',
        component: asyncLoadRouter('manager/machines/firmware/Form'),
        meta: { breadcrumb: (i18n) => [{ text: i18n.t('shared.actions.edit') }] },
        props: { action: 'update' },
      },
    ],
  },
]

export default firmware
