import { asyncLoadRouter } from '@router/helper'

const dashboard = [
  {
    path: '',
    component: asyncLoadRouter('Dashboard'),
    meta: {
      breadcrumb: (i18n) => [
        {
          text: i18n.t('shared.menus.dashboard'),
          to: { name: 'dashboard' },
        },
      ],
    },
    children: [
      {
        path: '',
        name: 'dashboard',
        component: asyncLoadRouter('dashboard/Show'),
        meta: { breadcrumb: (i18n) => [{ text: i18n.t('shared.actions.show') }] },
      },
    ],
  },
]

export default dashboard
