import Vue from 'vue'
import Vue2TouchEvents from 'vue2-touch-events'

Vue.use(Vue2TouchEvents, {
  disableClick: false,
  touchClass: 'touched',
  tapTolerance: 10,
  touchHoldTolerance: 500,
  swipeTolerance: 30,
  longTapTimeInterval: 500,
})
