import oauth from '@router/paths/oauth'
import dashboard from '@router/paths/dashboard'
import machines from '@router/paths/machines'
import organizations from '@router/paths/organizations'
import account from '@router/paths/account'
import organization from '@router/paths/organization'
import manager from '@router/paths/manager'
import statically from '@router/paths/statically'

export default () => {
  return [
    {
      path: '/',
      meta: {
        requiresAuth: true,
      },
      component: {
        render(h) {
          return h('router-view')
        },
      },
      children: [
        ...oauth,
        ...dashboard,
        ...machines,
        ...organizations,
        ...account,
        ...organization,
        ...manager,
        ...statically,
      ],
    },
    {
      path: '*',
      redirect: () => {
        return {
          path: '/',
        }
      },
    },
  ]
}
