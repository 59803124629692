import api from '@services/api'

export default {
  get(axios, params, query, config) {
    return api.get(axios, '/account', params, query, config)
  },

  update(axios, params, payload, config) {
    return api.update(axios, '/account', params, payload, config)
  },

  userinfo(axios, params, config) {
    return api.get(axios, '/account/userinfo', params, config)
  },

  panel(axios, params, config) {
    return api.get(axios, '/account/panel', params, config)
  },

  switchables(axios, params, config) {
    return api.get(axios, '/account/switchables', params, config)
  },

  uniqueIdentity(axios, params, query, config) {
    return api.get(axios, '/account/unique_identity', params, query, config)
  },

  attachment(axios, params, payload, config) {
    return api.update(axios, '/organization/attachment/:attachment', params, payload, config)
  },
}
