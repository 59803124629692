import api from '@services/api'

export default {
  panelOrganizations(axios, params, query, config) {
    return api.get(axios, '/base/panel_organizations', params, query, config)
  },

  panelMachines(axios, params, query, config) {
    return api.get(axios, '/base/panel_machines', params, query, config)
  },
}
