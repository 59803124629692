import dashboard from '@store/modules/manager/dashboard'
import identities from '@store/modules/manager/identities'
import accounts from '@store/modules/manager/accounts'
import organizations from '@store/modules/manager/organizations'
import machines from '@store/modules/manager/machines'
import firmwares from '@store/modules/manager/firmwares'

export default () => {
  return {
    namespaced: true,
    modules: {
      dashboard,
      identities,
      accounts,
      organizations,
      machines,
      firmwares,
    },
    state: () => ({}),
    getters: {},
    mutations: {},
    actions: {},
  }
}
