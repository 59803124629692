import api from '@services/api'

export default {
  list(axios, params, query, config) {
    return api.get(axios, '/manager/organizations', params, query, config)
  },

  get(axios, params, query, config) {
    return api.get(axios, '/manager/organizations/:id', params, query, config)
  },

  create(axios, params, payload, config) {
    return api.post(axios, '/manager/organizations', params, payload, config)
  },

  update(axios, params, payload, config) {
    return api.update(axios, '/manager/organizations/:id', params, payload, config)
  },

  destroy(axios, params, config) {
    return api.delete(axios, '/manager/organizations/:id', params, config)
  },

  panel(axios, params, query, config) {
    return api.get(axios, '/manager/organizations/:id/panel', params, query, config)
  },

  uniqueIdentity(axios, params, query, config) {
    return api.get(axios, '/manager/organizations/unique_identity', params, query, config)
  },

  transition(axios, params, payload, config) {
    return api.update(
      axios,
      '/manager/organizations/:id/transition/:transition',
      params,
      payload,
      config
    )
  },
}
