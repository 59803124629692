<template lang="pug">
  .wc-cookie-law
    b-modal#wc-cookie-law-consent.wc-cookie-law-consent(
      ref='modalConsent'
      content-class='overflow-visible'
      footer-class='d-none'
      v-model='consent.show'
      scrollable
      centered
      hide-header-close
      no-close-on-backdrop
      no-close-on-esc
      no-stacking)
      template(#modal-header)
        h5#wc-cookie-law-consent___BV_modal_title_.modal-title
          .fal.fa-fw.fa-shield-check.text-primary
          |  {{ $t('cookies.consent.title') }}
        ul.nav
          wc-language-switch.pl-3.py-1.shadow-none(
            toggle-class='shadow-none p-0'
            toggle-tag='a'
            variant='transparent'
            no-caret
            right)
            i.fal.fa-btn.fa-fw.fa-ellipsis-v.text-primary

      section.l-section
        .l-section-h
          |  {{ $t('cookies.consent.description') }}
          b-link(
            :to="{ name: 'dashboard' }")
            |  {{ $t('cookies.consent.gdpr') }}
          | .

      section.l-section
        .l-section-h
          b-button#wc-cookie-law-consent-accept.mt-4.text-white(
            variant='primary'
            @click.stop.prevent='onConsentSave'
            block)
            | {{ $t('cookies.consent.accept_button') }}
          b-button#wc-cookie-law-consent-settings.mt-2(
            variant='light'
            v-b-modal.wc-cookie-law-settings
            block)
            | {{ $t('cookies.consent.settings_button') }}

      template(#modal-footer)
        span

    b-modal#wc-cookie-law-settings.wc-cookie-law-settings(
      ref='modalSettings'
      footer-class='d-none'
      v-model='settings.show'
      scrollable
      centered
      hide-header-close
      no-close-on-backdrop
      no-close-on-esc
      no-stacking)
      template(#modal-title)
        i.fal.fa-fw.fa-shield-check.text-primary
        |  {{ $t('cookies.settings.title') }}

      section.l-section
        .l-section-h
          |  {{ $t('cookies.settings.description') }}

      section.l-section
        .l-section-h
          .cookie-options.accordion.mt-4(role='tablist')
            b-card.card-white.card-no-gutters.shadow.mb-2(no-body)
              b-card-header.p-0.border-bottom-0(role='tab')
                .d-flex.align-items-center
                  b-link.m-0.p-3.w-100.text-body.font-weight-bold(
                    v-b-toggle.cookie-law-strict)
                    i.fal.fa-fw.fa-chevron-down
                    |  {{ $t('cookies.settings.categories.system.title') }}
                  b-form-checkbox.pr-2(
                    size='lg'
                    :checked='true'
                    :disabled='true'
                    switch)
              b-collapse#cookie-law-strict(accordion='cookie-law-accordion' role='tabpanel')
                b-card-body
                  | {{ $t('cookies.settings.categories.system.description') }}

            b-card.card-white.card-no-gutters.shadow(no-body)
              b-card-header.p-0.border-bottom-0(role='tab')
                .d-flex.align-items-center
                  b-link.m-0.p-3.w-100.text-body.font-weight-bold(
                    v-b-toggle.cookie-law-analytics)
                    i.fal.fa-fw.fa-chevron-down
                    |  {{ $t('cookies.settings.categories.analytics.title') }}
                  b-form-checkbox.pr-2(
                    size='lg'
                    v-model='settings.levels.analytics'
                    switch)
              b-collapse#cookie-law-analytics(accordion='cookie-law-accordion' role='tabpanel')
                b-card-body
                  | {{ $t('cookies.settings.categories.analytics.description') }}

      section.l-section
        .l-section-h
          b-button#wc-cookie-law-settings-save.mt-4.text-white(
            variant='primary'
            @click.stop.prevent='onSettingsSave'
            block)
            | {{ $t('cookies.settings.save_button') }}
          b-button#wc-cookie-law-settings-cancel.mt-2(
            v-if='settings.fromConsent'
            variant='light'
            v-b-modal.wc-cookie-law-consent
            block)
            | {{ $t('shared.actions.cancel') }}
          b-button#wc-cookie-law-settings-cancel.mt-2(
            v-else
            variant='light'
            @click.stop.prevent='onSettingsCancel'
            block)
            | {{ $t('shared.actions.cancel') }}

      template(#modal-footer)
        span
</template>

<script>
import WcLanguageSwitch from '@components/shared/WcLanguageSwitch'
import { Gdpr } from '@common/gdpr'

export default {
  name: 'wc-cookie-law',
  mixins: [Gdpr],
  props: {
    skipRoutes: {
      type: Array,
      default: () => [],
    },
    skipRoutesNamespace: {
      type: Array,
      default: () => [],
    },
  },
  components: {
    WcLanguageSwitch,
  },
  methods: {
    showModalOnRoute(name) {
      this.consent.show =
        !this.skipRoutes.includes(name) &&
        !this.skipRoutesNamespace.find((n) => name.startsWith(n)) &&
        (!this.cookie ||
          this.cookie.rev !== this.consent.revision ||
          !this.$getDeep(this.cookie, 'level.system'))
          ? true
          : false
    },
  },
  watch: {
    '$route.name'(value) {
      this.showModalOnRoute(value)
    },
  },
  mounted() {
    this.showModalOnRoute(this.$route.name)
    this.checkSetAnalytics()
  },
}
</script>
