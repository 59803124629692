import { asyncLoadRouter } from '@router/helper'

const oauth = [
  {
    path: 'oauth',
    component: asyncLoadRouter('Oauth'),
    children: [
      {
        path: 'callback',
        name: 'oauth-callback',
        component: asyncLoadRouter('oauth/Callback'),
      },
      {
        path: 'callback/silent',
        name: 'oauth-callback-silent',
        component: asyncLoadRouter('oauth/CallbackSilent'),
      },
      {
        path: 'login',
        name: 'oauth-login',
        component: asyncLoadRouter('oauth/Login'),
      },
    ],
  },
]

export default oauth
