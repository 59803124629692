import apiMachines from '@services/api/manager/machines'
import analytics from '@store/modules/manager/machines/analytics'

export default {
  namespaced: true,
  modules: {
    analytics,
  },
  actions: {
    getPanel(_, payload) {
      return apiMachines.panel(this.$http, payload)
    },
  },
}
