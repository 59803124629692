/* See https://bootstrap-vue.org/docs#browser-support */
import 'core-js/stable'
import 'regenerator-runtime/runtime'
import 'intersection-observer'

import Vue from 'vue'
import * as Sentry from '@sentry/browser'
import { Vue as VueIntegration } from '@sentry/integrations'
import wc from '@common/wc'
import createI18n from '@common/i18n'
import createStore from '@store'
import { createRouter, beforeEachRoute } from '@router'
import attachApm from '@common/apm'
import attachGtag from '@common/gtag'
import container from '@views/Container'
import '@common/deep'
import '@common/meta'
import '@common/registerServiceWorker'
import '@common/notifications'
import '@common/alerts'
import '@common/bootstrap'
import '@common/touch'
import '@common/viewport'
import '@assets/css/plugins/fontawesome.scss'
import '@assets/css/plugins/bootstrap/init.scss'
import '@assets/css/plugins/animate.css'
import 'sweetalert2/dist/sweetalert2.min.css'
import '@assets/css/plugins/sweetalert.scss'
import 'vue-content-placeholders/src/styles.scss'
import '@assets/css/app.scss'

Vue.config.productionTip = false

/* Add Sentry tracker. */
if (process.env.VUE_APP_WC_CORE_SENTRY_DSN) {
  Sentry.init({
    dsn: process.env.VUE_APP_WC_CORE_SENTRY_DSN,
    environment: process.env.VUE_APP_WC_CORE_STAGE,
    integrations: [new VueIntegration({ Vue, attachProps: true })],
  })
  /**
   * Add debug: true to init or capture an error to test Sentry.
   * Sentry.captureException(new Error('This is a test error!'))
   */
}

export default () => {
  const i18n = createI18n(wc)
  const store = createStore(wc)
  const router = createRouter(wc)

  attachApm(wc, router)
  attachGtag(wc, router)
  beforeEachRoute(router, store)

  /* Load components globally */
  Vue.component('loading', () => import('@components/shared/WcLoader'))

  return new Vue({
    wc,
    i18n,
    store,
    router,
    render: (h) => h(container, { ref: 'container' }),
  })
}
