export default {
  imports: [
    {
      src: '@common/cable',
      ssr: false,
    },
  ],
  plugins: [
    '@uvue/core/plugins/asyncData',
    [
      '@uvue/core/plugins/vuex',
      {
        onHttpRequest: true,
        fetch: true,
      },
    ],
    '@uvue/core/plugins/middlewares',
    '@uvue/core/plugins/errorHandler',
    '@plugins/axios',
    '@plugins/config',
    '@plugins/i18n',
    '@plugins/day',
    '@plugins/auth',
  ],
}
