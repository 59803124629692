import apiConfig from '@services/api/utils/config'
import config from '@assets/config.json'

class Config {
  constructor(options = { conf: {} }) {
    this.options = options
    this.shared = {}
    this.default()
  }

  default() {
    try {
      this.conf = config.conf
      this.conf = Object.assign({}, this.conf, {
        env: { short: process.env.VUE_APP_WC_CORE_STAGE.charAt(0) },
        live: { is_mobile: this.isMobile(), is_geolocation: this.isGeolocation() },
      })
    } catch (error) {
      this.conf = {}
    }
  }

  load(axios, query = this.options.conf.query) {
    return apiConfig
      .load(axios, {}, { util: { query: query || 'all' } })
      .promise.then((response) => {
        if (response && response.data)
          this.conf = Object.assign({}, this.conf, response.data.attributes.load)
        return this.conf
      })
      .catch((error) => {
        return error
      })
  }

  isMobile() {
    return process.client || process.spa
      ? typeof window.orientation !== 'undefined' || navigator.userAgent.indexOf('IEMobile') !== -1
      : false
  }

  isGeolocation() {
    return process.client || process.spa ? 'geolocation' in navigator : false
  }
}

export default Config
