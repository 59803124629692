import api from '@services/api'

export default {
  get(axios, params, query, config) {
    return api.get(axios, '/machines/:machine_id/analytics/report', params, query, config)
  },

  panelMachine(axios, params, query, config) {
    return api.get(
      axios,
      '/machines/:machine_id/analytics/report/panel_machine',
      params,
      query,
      config
    )
  },

  panelBatteries(axios, params, query, config) {
    return api.get(
      axios,
      '/machines/:machine_id/analytics/report/panel_batteries',
      params,
      query,
      config
    )
  },

  panelTemperatures(axios, params, query, config) {
    return api.get(
      axios,
      '/machines/:machine_id/analytics/report/panel_temperatures',
      params,
      query,
      config
    )
  },

  panelOperatingHours(axios, params, query, config) {
    return api.get(
      axios,
      '/machines/:machine_id/analytics/report/panel_operating_hours',
      params,
      query,
      config
    )
  },

  panelTilt(axios, params, query, config) {
    return api.get(
      axios,
      '/machines/:machine_id/analytics/report/panel_tilt',
      params,
      query,
      config
    )
  },

  panelMotorsPercentage(axios, params, query, config) {
    return api.get(
      axios,
      '/machines/:machine_id/analytics/report/panel_motors_percentage',
      params,
      query,
      config
    )
  },

  panelMotorsVoltage(axios, params, query, config) {
    return api.get(
      axios,
      '/machines/:machine_id/analytics/report/panel_motors_voltage',
      params,
      query,
      config
    )
  },

  panelMotorsCurrent(axios, params, query, config) {
    return api.get(
      axios,
      '/machines/:machine_id/analytics/report/panel_motors_current',
      params,
      query,
      config
    )
  },

  panelMap(axios, params, query, config) {
    return api.get(axios, '/machines/:machine_id/analytics/report/panel_map', params, query, config)
  },
}
