import api from '@services/api'

export default {
  list(axios, params, query, config) {
    return api.get(axios, '/manager/machines', params, query, config)
  },

  get(axios, params, query, config) {
    return api.get(axios, '/manager/machines/:id', params, query, config)
  },

  create(axios, params, payload, config) {
    return api.post(axios, '/manager/machines', params, payload, config)
  },

  update(axios, params, payload, config) {
    return api.update(axios, '/manager/machines/:id', params, payload, config)
  },

  destroy(axios, params, config) {
    return api.delete(axios, '/manager/machines/:id', params, config)
  },

  panel(axios, params, query, config) {
    return api.get(axios, '/manager/machines/:id/panel', params, query, config)
  },

  uniqueSerial(axios, params, query, config) {
    return api.get(axios, '/manager/machines/unique_serial', params, query, config)
  },

  searchOrganization(axios, params, query, config) {
    return api.get(axios, '/manager/machines/search_organization', params, query, config)
  },

  transition(axios, params, payload, config) {
    return api.update(
      axios,
      '/manager/machines/:id/transition/:transition',
      params,
      payload,
      config
    )
  },
}
