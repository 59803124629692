import { asyncLoadRouter } from '@router/helper'

const roles = [
  {
    path: 'roles',
    component: asyncLoadRouter('manager/organizations/Roles'),
    redirect: (to) => {
      return {
        name: 'manager-organizations-roles-edit',
        params: { organization_id: to.params.organization_id },
      }
    },
    meta: {
      breadcrumb: (i18n, params) => [
        {
          text: i18n.t('activerecord.models.role.other'),
          to: {
            name: 'manager-organizations-roles-edit',
            params: { organization_id: params.organization_id },
          },
        },
      ],
    },
    children: [
      {
        path: 'edit',
        name: 'manager-organizations-roles-edit',
        component: asyncLoadRouter('manager/organizations/roles/Form'),
        meta: { breadcrumb: (i18n) => [{ text: i18n.t('shared.actions.edit') }] },
        props: { action: 'update' },
      },
    ],
  },
]

export default roles
