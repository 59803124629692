import { asyncLoadRouter } from '@router/helper'

const telemetry = [
  {
    path: 'telemetry',
    component: asyncLoadRouter('manager/machines/Telemetry'),
    meta: {
      breadcrumb: (i18n) => [
        {
          text: i18n.t('machine.telemetry.title'),
          to: { name: 'manager-machines-telemetry' },
        },
      ],
    },
    children: [
      {
        path: '',
        name: 'manager-machines-telemetry',
        component: asyncLoadRouter('manager/machines/telemetry/Show'),
        meta: { breadcrumb: (i18n) => [{ text: i18n.t('shared.actions.show') }] },
      },
    ],
  },
]

export default telemetry
