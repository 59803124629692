import api from '@services/api'

export default {
  panelAccounts(axios, params, query, config) {
    return api.get(axios, '/manager/base/panel_accounts', params, query, config)
  },

  panelOrganizations(axios, params, query, config) {
    return api.get(axios, '/manager/base/panel_organizations', params, query, config)
  },

  panelMachines(axios, params, query, config) {
    return api.get(axios, '/manager/base/panel_machines', params, query, config)
  },

  panelFirmwares(axios, params, query, config) {
    return api.get(axios, '/manager/base/panel_firmwares', params, query, config)
  },

  panelMachinesOnline(axios, params, query, config) {
    return api.get(axios, '/manager/base/panel_machines_online', params, query, config)
  },
}
