import CookieStorage from '@common/storage/cookieStorage'
import axios from 'axios'

export default {
  beforeCreate(context, inject, vueOptions) {
    const { router, store, req } = context
    const { wc } = vueOptions

    /* Create axios instance. */
    const instance = axios.create({
      withCredentials: false,
      baseURL: req ? process.env.VUE_APP_WC_CORE_API_IURL : process.env.VUE_APP_WC_CORE_API_URL,
      headers: {
        'WC-Client': process.env.VUE_APP_WC_CORE_AUD,
        Accept: 'application/vnd.api+json, application/vnd.windegger.v1+json',
        'Content-Type': 'application/vnd.api+json',
      },
    })

    /* Add cancelToken property. */
    instance.CancelToken = axios.CancelToken

    /* Request interceptor. */
    instance.interceptors.request.use((config) => {
      /* Set origin header on server side requests. */
      if (req) config.headers.origin = process.env.VUE_APP_WC_CORE_WEB_URL

      /* Don't pass sensible header data if URL is external. */
      if (config.url.startsWith('/') || config.url.startsWith(config.baseURL)) {
        /* Set authentication bearer token. */
        const token = req
          ? req.cookies[`_${wc.conf.base.prefix}${wc.conf.env.short}_token`]
          : CookieStorage.getItem(`_${wc.conf.base.prefix}${wc.conf.env.short}_token`)
        if (token) config.headers['Authorization'] = `Bearer ${token}`

        /* Set organization header. */
        const org = req
          ? req.cookies[`_${wc.conf.base.prefix}${wc.conf.env.short}_org`]
          : CookieStorage.getItem(`_${wc.conf.base.prefix}${wc.conf.env.short}_org`)
        if (org) config.headers['Organization'] = `${org}`
      }

      /* Set accepted locale. */
      config.headers['Accept-Language'] = wc.shared.locale || store.getters['ui/getLocale']

      /* Set WC token if included in request headers. */
      if (req && req.headers['wc_api_token'])
        config.headers['WC_API_TOKEN'] = req.headers['wc_api_token']

      return config
    })

    /* Response interceptor. */
    instance.interceptors.response.use(
      (response) => {
        if (
          !response.config.isBackground &&
          response.data &&
          response.data.meta &&
          response.data.meta.redirect
        ) {
          response.data.meta.redirect.remote
            ? window.location.replace(response.data.meta.redirect.remote)
            : router.push(response.data.meta.redirect)
        }
        return Promise.resolve(response)
      },
      async (error) => {
        /* The request was made and the server responded with a status code. */
        if (!error.config || !error.config.isBackground) {
          if (error.response) {
            if (error.response.status === 401) {
              /* Check if app is initialized to be able to push. */
              if (router.app) {
                return await router.app.$auth // eslint-disable-line promise/no-promise-in-callback
                  .signInSilent({ onCatchCleanup: true })
                  .then(() => {
                    return instance.request(error.config)
                  })
                  .catch((error) => {
                    router.push({ name: 'dashboard' })
                    return error
                  })
              } else {
                await store.dispatch('auth/removeCurrentAccount')
                await store.dispatch('auth/removeCurrentOrganization')
              }
            } else if (
              error.response.data &&
              error.response.data.meta &&
              error.response.data.meta.redirect
            ) {
              router.push(error.response.data.meta.redirect)
            }
            /* The request was made but no response was received. */
          } else if (error.request) {
            error.request.data = {
              meta: {
                notices: [
                  {
                    id: new Date() / 100000,
                    type: 'danger',
                    title: router.app
                      ? router.app.$i18n.t('notices.network.no_response.error.title')
                      : 'Error',
                    detail: router.app
                      ? router.app.$i18n.t('notices.network.no_response.error.detail')
                      : 'The request was made but no response was received.',
                  },
                ],
              },
            }
            /* Something happened in setting up the request. */
          } else {
            error.data = {
              meta: {
                notices: [
                  {
                    id: new Date() / 100000,
                    type: 'danger',
                    title: router.app
                      ? router.app.$i18n.t('notices.network.no_request.error.title')
                      : 'Error',
                    detail: router.app
                      ? router.app.$i18n.t('notices.network.no_request.error.detail')
                      : 'Something happened performing the request.',
                  },
                ],
              },
            }
          }
        }
        return Promise.reject(error)
      }
    )

    inject('http', instance)
  },
}
