import { asyncLoadRouter } from '@router/helper'
import telemetry from '@router/paths/machines/telemetry'
import analytics from '@router/paths/machines/analytics'
import reports from '@router/paths/machines/reports'

const machines = [
  {
    path: 'machines',
    component: asyncLoadRouter('Machines'),
    meta: {
      breadcrumb: (i18n) => [
        {
          text: i18n.t('activerecord.models.machine.other'),
          to: { name: 'machines' },
        },
      ],
    },
    children: [
      {
        path: '',
        name: 'machines',
        component: asyncLoadRouter('machines/List'),
        meta: { breadcrumb: (i18n) => [{ text: i18n.t('shared.actions.list') }] },
      },
      {
        path: ':machine_id([A-Za-z\\d]{22})',
        name: 'machines-show',
        component: asyncLoadRouter('machines/Show'),
        redirect: (to) => {
          return {
            name: 'machines-telemetry',
            params: { machine_id: to.params.machine_id },
          }
        },
        meta: {
          breadcrumb: (i18n, params) => [
            {
              text: `ID ${params.machine_id.substring(0, 5)}...`,
              to: {
                name: 'machines-telemetry',
                params: { machine_id: params.machine_id },
              },
            },
          ],
        },
        children: [...telemetry, ...analytics, ...reports],
      },
    ],
  },
  {
    path: 'machines/:machine_id([A-Za-z\\d]{22})/analytics/report',
    component: asyncLoadRouter('machines/analytics/Report'),
    meta: {
      breadcrumb: (i18n, params) => [
        { text: i18n.t('activerecord.models.machine.other') },
        { text: `ID ${params.machine_id.substring(0, 5)}...` },
        { text: i18n.t('machine.analytics.title') },
        { text: i18n.t('activerecord.models.machine/report.one') },
      ],
    },
    children: [
      {
        path: '',
        name: 'machines-analytics-report',
        component: asyncLoadRouter('machines/analytics/report/Show'),
        meta: {
          requiresAuth: false,
          breadcrumb: (i18n) => [{ text: i18n.t('shared.actions.show') }],
        },
      },
    ],
  },
]

export default machines
