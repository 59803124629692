import Vue from 'vue'
import VueRouter from 'vue-router'
import routes from '@router/paths'

Vue.use(VueRouter)

/**
 * @param {object} router A router instance.
 * @param {object} store A store instance.
 */
export function beforeEachRoute(router, store) {
  /**
   * Check if locale basic translations are loaded.
   *
   * @param {string} locale A locale to check.
   * @returns {Promise} A Promise value.
   */
  function checkLocaleLoaded(locale) {
    if (!store.getters['ui/getLocalesLoaded'][locale].length) {
      return store.dispatch('ui/loadLocale', {
        locale: locale,
        query: '.',
      })
    }
    return Promise.resolve()
  }

  /**
   *  Check if requested locale is set.
   *
   * @param {string} locale A locale to check.
   * @returns {string} A locale.
   */
  function checkLocaleSet(locale) {
    if (
      router.app.$wc.shared.locale !== locale ||
      router.app.$i18n.locale !== locale ||
      store.getters['ui/getLocale'] !== locale
    ) {
      store.cache.clear()
      return store.dispatch('ui/setLocale', locale)
    }
  }

  /**
   *  Check if requested route requires some roles.
   *
   * @param {object} to A route to object.
   * @returns {Array} An array with route roles.
   */
  function checkAuthorization(to) {
    const auth = to.matched.filter((record) => record.meta.authorize).pop()
    if (!auth) return null

    const roles =
      typeof auth.meta.authorize === 'function'
        ? auth.meta.authorize(to.params)
        : auth.meta.authorize

    return roles &&
      !roles.filter((r) => router.app.$store.getters['auth/currentAccount'].rol.includes(r)).length
      ? true
      : false
  }

  router.beforeEach(async (to, from, next) => {
    /* Set referer. */
    router.referer = from

    /* Check locale and set new locale if required. */
    let locale = to.query.l || router.app.$wc.shared.locale || store.getters['ui/getLocale']

    if (!router.app.$wc.conf.base.available_locales.includes(locale))
      locale = store.getters['ui/getLocale']

    /* Await locale is loaded. */
    await checkLocaleLoaded(locale)

    /* Set requested locale. */
    checkLocaleSet(locale)

    /* Handle oauth routes and do next. */
    if (to.name.startsWith('oauth-')) return next()

    /* Check required authentication and authorization. */
    if (
      to.matched.some((record) => record.meta.requiresAuth === true) &&
      to.matched[to.matched.length - 1].meta.requiresAuth !== false
    ) {
      /* Check authentication. */
      if (!router.app.$store.getters['auth/isAuthenticated']) {
        /* Client side only. */
        if (process.client || process.spa) {
          return router.app.$auth.signIn({ ui_locales: locale })
        } else {
          return router.push({ name: 'oauth-login' })
        }
        /* Check authorization. */
      } else if (checkAuthorization(to)) {
        return router.push({ name: 'dashboard' })
      }
    }

    next()
  })
}

/**
 * @param {object} wc A config instance.
 * @returns {object} A router instance.
 */
export function createRouter(wc) {
  const router = new VueRouter({
    mode: 'history',
    base: process.env.BASE_URL,
    fallback: false,
    scrollBehavior: (to, from, savedPosition) => {
      if (savedPosition) {
        return savedPosition
      } else {
        return { x: 0, y: 0 }
      }
    },
    linkExactActiveClass: 'active',
    routes: routes(wc),
  })

  /**
   * See: https://stackoverflow.com/questions/57837758/navigationduplicated-navigating-to-current-location-search-is-not-allowed
   * Related to: https://github.com/universal-vue/uvue/blob/dev/packages/%40uvue/core/lib/server.js#L34
   *
   * @param {object} router A router instance.
   * @param {string} methodName A method name as string.
   */
  function patchRouterMethod(router, methodName) {
    router['old' + methodName] = router[methodName]
    router[methodName] = async function (location) {
      return router['old' + methodName](location).catch((error) => {
        if (error.name === 'NavigationDuplicated') {
          return this.currentRoute
        }
        throw error
      })
    }
  }

  patchRouterMethod(router, 'push')
  patchRouterMethod(router, 'replace')

  return router
}
