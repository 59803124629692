import apiIdentities from '@services/api/manager/identities'

export default {
  namespaced: true,
  actions: {
    getPanel(_, payload) {
      return apiIdentities.panel(this.$http, payload)
    },
  },
}
