import Vue from 'vue'
import Oidc from 'oidc-client'
import StorageFactory from '@common/storage/storageFactory'
import CookieStorage from '@common/storage/cookieStorage'
import wc from '@common/wc'

/**
 * @param {object} store  The store object.
 * @returns {object} The OIDC UserManager instance.
 */
function userManager(store) {
  /* Debug config. */
  /*
  Oidc.Log.logger = console
  Oidc.Log.level = Oidc.Log.DEBUG
  */
  const mgr = new Oidc.UserManager({
    userStore: new Oidc.WebStorageStateStore({
      prefix: `_${wc.conf.base.prefix}${wc.conf.env.short}_oauth.`,
      store: StorageFactory.getStorage(),
    }),
    authority: process.env.VUE_APP_WC_OIDC_AUTHORITY,
    client_id: process.env.VUE_APP_WC_OIDC_CLIENT_ID,
    redirect_uri: process.env.VUE_APP_WC_OIDC_REDIRECT_URI,
    silent_redirect_uri: `${process.env.VUE_APP_WC_OIDC_REDIRECT_URI}/silent`,
    post_logout_redirect_uri: process.env.VUE_APP_WC_CORE_WEB_URL,
    response_type: 'code',
    scope: 'openid email profile',
    automaticSilentRenew: true,
    loadUserInfo: true,
    filterProtocolClaims: true,
    revokeAccessTokenOnSignout: true,
    extraQueryParams: { redirect_to: window.location.href },
  })

  mgr.events.addAccessTokenExpired(() => {
    mgr
      .signinSilent()
      .then(async (session) => {
        setTokenCookie(session, store)
        mgr.clearStaleState()
        return session
      })
      .catch((error) => {
        return error
      })
  })

  mgr.events.addUserUnloaded(() => {
    removeCurrentAccount(store)
  })

  return mgr
}

/**
 * @param {object} session The actual session object.
 * @param {object} store The store object.
 * @returns {string} The cookie action return.
 */
function setTokenCookie(session, store) {
  return session
    ? CookieStorage.setItem(`_${wc.conf.base.prefix}${wc.conf.env.short}_token`, session.id_token, {
        expires: store.$dayjs().add(1, 'year').toDate(),
      })
    : removeCurrentAccount(store)
}

/**
 * Remove authentication related cookies and set store states.
 *
 * @param {object} store The store object.
 */
async function removeCurrentAccount(store) {
  await store.dispatch('auth/removeCurrentAccount')
  await store.dispatch('auth/removeCurrentOrganization')
}

/**
 * @param {object} store The store object.
 * @returns {object} The current account.
 */
async function currentAccount(store) {
  if (!store.getters['auth/isAuthenticated']) {
    await store.dispatch('auth/setCurrentAccount')
    await store.dispatch(
      'auth/setCurrentOrganization',
      CookieStorage.getItem(`_${wc.conf.base.prefix}${wc.conf.env.short}_org`)
    )
  }
}

export default {
  async beforeCreate(context, inject, vueOptions) {
    const { store } = vueOptions
    const mgr = process.client || process.spa ? userManager(store) : null
    const auth = new Vue({
      methods: {
        async initAuth() {
          if (mgr) {
            const session = await mgr.getUser()
            if (session && !session.expired) {
              setTokenCookie(session, store)
            } else if (session) {
              await this.signInSilent().then((session) => {
                return setTokenCookie(session, store)
              })
            }
            mgr.clearStaleState()
          }
          await currentAccount(store)
        },
        signIn(config = {}) {
          return mgr.signinRedirect(config).catch((error) => {
            throw error
          })
        },
        signOut(config = {}) {
          return mgr.signoutRedirect(config).catch((error) => {
            throw error
          })
        },
        signInSilent(config = {}) {
          return mgr
            .signinSilent(config)
            .then(async (session) => {
              setTokenCookie(session, store)
              mgr.clearStaleState()
              return session
            })
            .catch((error) => {
              if (config.onCatchCleanup) {
                removeCurrentAccount(store)
              }
              throw error
            })
        },
        signInCallback(config = {}) {
          return mgr
            .signinCallback(config)
            .then(async (session) => {
              setTokenCookie(session, store)
              await currentAccount(store)
              mgr.clearStaleState()
              return session
            })
            .catch((error) => {
              throw error
            })
        },
        signInCallbackSilent(config = {}) {
          return mgr
            .signinSilentCallback(config)
            .then(async (session) => {
              setTokenCookie(session, store)
              await currentAccount(store)
              mgr.clearStaleState()
              return session
            })
            .catch((error) => {
              throw error
            })
        },
        async getUser() {
          return await mgr
            .getUser()
            .then((session) => {
              return session
            })
            .catch((error) => {
              throw error
            })
        },
      },
    })

    inject('auth', auth)

    /* Initialize authentication (cleanup and set cookies). */
    await auth.initAuth()
  },
}
