import CookieStorage from '@common/storage/cookieStorage'
import { bootstrap, setOptions } from 'vue-gtag'

/**
 * @param {string} level A cookie level string.4
 * @param {object} wc A config instance.
 * @returns {boolean} If cookie accepted for given level.
 */
export function cookieAllowed(level, wc) {
  const cookie = JSON.parse(CookieStorage.getItem(`_wc${wc.conf.env.short}_cookies`) || 'false')
  return cookie && cookie.level && cookie.level[level]
}

export const Gdpr = {
  methods: {
    onConsentSave() {
      this.cookie = {
        level: {
          system: true,
          analytics: true,
        },
        rev: this.consent.revision,
      }
      CookieStorage.setItem(`_wc${this.$wc.conf.env.short}_cookies`, JSON.stringify(this.cookie), {
        expires: this.$dayjs().add(30, 'year').toDate(),
      })
      this.consent.show = false
      this.checkSetAnalytics()
    },
    onSettingsSave() {
      this.cookie = {
        level: {
          system: true,
          analytics: this.settings.levels.analytics,
        },
        rev: this.consent.revision,
      }
      CookieStorage.setItem(`_wc${this.$wc.conf.env.short}_cookies`, JSON.stringify(this.cookie), {
        expires: this.$dayjs().add(30, 'year').toDate(),
      })
      this.consent.show = this.settings.show = false
      this.checkSetAnalytics()
    },
    onSettingsShow() {
      this.settings.fromConsent = false
      this.settings.show = true
    },
    onSettingsCancel() {
      this.settings.show = false
      this.settings.fromConsent = true
    },
    async checkSetAnalytics() {
      if (this.$wc.conf.analytics.google.enabled || !cookieAllowed('analytics', this.$wc)) {
        this.$gtag.optOut()
      } else {
        if (!this.analytics.gtag.loaded) {
          setOptions({ config: { id: this.$wc.conf.analytics.google.id } })
          await bootstrap()
          this.analytics.gtag.loaded = true
        }
        this.$gtag.optIn()
      }
    },
  },

  data() {
    const cookie =
      process.client || process.spa
        ? JSON.parse(CookieStorage.getItem(`_wc${this.$wc.conf.env.short}_cookies`) || 'false')
        : false
    return {
      cookie: cookie,
      consent: {
        show: false,
        revision: 1 /* Changing revision number will re-ask all users to accept new cookies. */,
      },
      settings: {
        show: false,
        fromConsent: true,
        levels: {
          system: true,
          analytics: cookie ? this.$getDeep(cookie, 'level.analytics') : true,
        },
      },
      analytics: {
        gtag: {
          loaded: false,
        },
      },
    }
  },
}
