import CookieStorage from '@common/storage/cookieStorage'
import apiI18n from '@services/api/utils/i18n'

/**
 * @param {object} wc A configuration object.
 * @returns {string} A window width.
 */
function defineWindowWidth(wc) {
  return (process.client || process.spa ? window.innerWidth : wc.conf.base.mobile_breakpoint - 1) <
    wc.conf.base.mobile_breakpoint
    ? false
    : true
}

export default (wc) => {
  return {
    namespaced: true,
    state: () => ({
      windowWidth:
        process.client || process.spa ? window.innerWidth : wc.conf.base.mobile_breakpoint,
      aside: defineWindowWidth(wc),
      locale: {
        active: wc.shared.locale || wc.conf.base.default_locale,
        loaded: Object.assign(...wc.conf.base.available_locales.map((k) => ({ [k]: [] }))),
      },
    }),
    getters: {
      windowWidth: (state) => state.windowWidth,
      isAsideOpen: (state) => state.aside,
      isMediumDevice: (state) =>
        state.windowWidth < wc.conf.base.mobile_breakpoint ? true : false,
      getLocale: (state) => state.locale.active,
      getLocalesLoaded: (state) => state.locale.loaded,
    },
    mutations: {
      windowChanged(state) {
        state.windowWidth = window.innerWidth
        state.aside = defineWindowWidth(wc)
      },
      toggleAside(state) {
        state.aside = !state.aside
      },
      openAside(state) {
        state.aside = true
      },
      closeAside(state) {
        state.aside = false
      },
      setLocale(state, value) {
        wc.shared.locale = value
        state.locale.active = value
        this.$i18n.locale = value
        CookieStorage.setItem(`_${wc.conf.base.prefix}${wc.conf.env.short}_locale`, value, {
          expires: this.$dayjs().add(30, 'year').toDate(),
        })
        require(`dayjs/locale/${value}.js`)
        this.$dayjs.locale(value)
      },
      loadLocale(state, locale) {
        for (let load in locale.data) {
          this.$i18n.mergeLocaleMessage(load, locale.data[load])
          state.locale.loaded[load] = [
            ...new Set([...state.locale.loaded[load], ...locale.payload.query]),
          ]
        }
      },
    },
    actions: {
      windowChanged({ commit }) {
        commit('windowChanged')
      },
      toggleAside({ commit }) {
        commit('toggleAside')
      },
      openAside({ commit }) {
        commit('openAside')
      },
      closeAside({ commit }) {
        commit('closeAside')
      },
      setLocale({ commit }, data) {
        if (!wc.conf.base.available_locales.includes(data)) data = wc.conf.base.default_locale
        commit('setLocale', data)
      },
      loadLocale({ commit }, payload) {
        return apiI18n
          .load(this.$http, {}, { util: payload })
          .promise.then((response) => {
            if (response && response.data) {
              commit('loadLocale', {
                payload: payload,
                data: response.data.attributes.load,
              })
            }
            return response
          })
          .catch((error) => {
            throw error
          })
      },
    },
  }
}
