import { asyncLoadRouter } from '@router/helper'

const account = [
  {
    path: 'account',
    component: asyncLoadRouter('Account'),
    children: [
      {
        path: '',
        name: 'account-show',
        component: asyncLoadRouter('account/Show'),
        redirect: () => {
          return { name: 'account-edit' }
        },
        meta: {
          breadcrumb: (i18n) => [
            { text: i18n.t('account.menus.my_account'), to: { name: 'account-edit' } },
          ],
        },
        children: [
          {
            path: 'edit',
            name: 'account-edit',
            component: asyncLoadRouter('account/Form'),
            meta: { breadcrumb: (i18n) => [{ text: i18n.t('shared.actions.edit') }] },
            props: { action: 'update' },
          },
        ],
      },
      {
        path: 'profile',
        component: asyncLoadRouter('account/Profile'),
        redirect: () => {
          return { name: 'account-profile-edit' }
        },
        meta: {
          breadcrumb: (i18n) => [
            {
              text: i18n.t('account/profile.menus.my_profile'),
              to: { name: 'account-profile-edit' },
            },
          ],
        },
        children: [
          {
            path: 'edit',
            name: 'account-profile-edit',
            component: asyncLoadRouter('account/profile/Form'),
            meta: { breadcrumb: (i18n) => [{ text: i18n.t('shared.actions.edit') }] },
            props: { action: 'update' },
          },
        ],
      },
      {
        path: 'contact',
        component: asyncLoadRouter('account/Contact'),
        redirect: () => {
          return { name: 'account-contact-edit' }
        },
        meta: {
          breadcrumb: (i18n) => [
            {
              text: i18n.t('contact.menus.my_contact'),
              to: { name: 'account-contact-edit' },
            },
          ],
        },
        children: [
          {
            path: 'edit',
            name: 'account-contact-edit',
            component: asyncLoadRouter('account/contact/Form'),
            meta: { breadcrumb: (i18n) => [{ text: i18n.t('shared.actions.edit') }] },
            props: { action: 'update' },
          },
        ],
      },
      {
        path: 'address',
        component: asyncLoadRouter('account/Address'),
        redirect: () => {
          return { name: 'account-address-edit' }
        },
        meta: {
          breadcrumb: (i18n) => [
            {
              text: i18n.t('address.menus.my_address'),
              to: { name: 'account-address-edit' },
            },
          ],
        },
        children: [
          {
            path: 'edit',
            name: 'account-address-edit',
            component: asyncLoadRouter('account/address/Form'),
            meta: { breadcrumb: (i18n) => [{ text: i18n.t('shared.actions.edit') }] },
            props: { action: 'update' },
          },
        ],
      },
    ],
  },
]

export default account
