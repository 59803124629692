import { asyncLoadRouter } from '@router/helper'

const link_networks = [
  {
    path: 'contacts/link_networks',
    component: asyncLoadRouter('manager/globals/contacts/LinkNetworks'),
    meta: {
      authorize: ['admin'],
      breadcrumb: (i18n) => [
        { text: i18n.t('activerecord.models.contact.other') },
        {
          text: i18n.t('activerecord.models.contact/link_network.other'),
          to: { name: 'manager-contacts-link_networks' },
        },
      ],
    },
    children: [
      {
        path: '',
        name: 'manager-contacts-link_networks',
        component: asyncLoadRouter('manager/globals/contacts/link_networks/List'),
        meta: { breadcrumb: (i18n) => [{ text: i18n.t('shared.actions.list') }] },
      },
      {
        path: 'new',
        name: 'manager-contacts-link_networks-new',
        component: asyncLoadRouter('manager/globals/contacts/link_networks/New'),
        meta: {
          breadcrumb: (i18n) => [
            {
              text: i18n.t('shared.actions.new'),
              to: { name: 'manager-contacts-link_networks-new' },
            },
          ],
        },
        props: { action: 'create' },
      },
      {
        path: ':link_network_id([A-Za-z\\d]{22})',
        name: 'manager-contacts-link_networks-show',
        component: asyncLoadRouter('manager/globals/contacts/link_networks/Show'),
        redirect: (to) => {
          return {
            name: 'manager-contacts-link_networks-edit',
            params: { link_network_id: to.params.link_network_id },
          }
        },
        meta: {
          breadcrumb: (i18n, params) => [
            {
              text: `ID ${params.link_network_id}`,
              to: {
                name: 'manager-contacts-link_networks-edit',
                params: { link_network_id: params.link_network_id },
              },
            },
          ],
        },
        children: [
          {
            path: 'edit',
            name: 'manager-contacts-link_networks-edit',
            component: asyncLoadRouter('manager/globals/contacts/link_networks/Form'),
            meta: { breadcrumb: (i18n) => [{ text: i18n.t('shared.actions.edit') }] },
            props: { action: 'update' },
          },
        ],
      },
    ],
  },
]

export default link_networks
