import { asyncLoadRouter } from '@router/helper'
import machines from '@router/paths/manager/organizations/machines'
import categories from '@router/paths/manager/organizations/categories'
import roles from '@router/paths/manager/organizations/roles'

const organizations = [
  {
    path: 'organizations',
    component: asyncLoadRouter('manager/Organizations'),
    meta: {
      authorize: ['admin', 'manager', 'technician'],
      breadcrumb: (i18n) => [
        {
          text: i18n.t('activerecord.models.organization.other'),
          to: { name: 'manager-organizations' },
        },
      ],
    },
    children: [
      {
        path: '',
        name: 'manager-organizations',
        component: asyncLoadRouter('manager/organizations/List'),
        meta: { breadcrumb: (i18n) => [{ text: i18n.t('shared.actions.list') }] },
      },
      {
        path: 'new',
        name: 'manager-organizations-new',
        component: asyncLoadRouter('manager/organizations/New'),
        meta: {
          breadcrumb: (i18n) => [
            {
              text: i18n.t('shared.actions.new'),
              to: { name: 'manager-organizations-new' },
            },
          ],
        },
        props: { action: 'create' },
      },
      {
        path: ':organization_id([A-Za-z\\d]{22})',
        name: 'manager-organizations-show',
        component: asyncLoadRouter('manager/organizations/Show'),
        redirect: (to) => {
          return {
            name: 'manager-organizations-edit',
            params: { organization_id: to.params.organization_id },
          }
        },
        meta: {
          breadcrumb: (i18n, params) => [
            {
              text: `ID ${params.organization_id}`,
              to: {
                name: 'manager-organizations-edit',
                params: { organization_id: params.organization_id },
              },
            },
          ],
        },
        children: [
          {
            path: 'edit',
            name: 'manager-organizations-edit',
            component: asyncLoadRouter('manager/organizations/Form'),
            meta: { breadcrumb: (i18n) => [{ text: i18n.t('shared.actions.edit') }] },
            props: { action: 'update' },
          },
          {
            path: 'profile',
            component: asyncLoadRouter('manager/organizations/Profile'),
            redirect: (to) => {
              return {
                name: 'manager-organizations-profile-edit',
                params: { organization_id: to.params.organization_id },
              }
            },
            meta: {
              breadcrumb: (i18n, params) => [
                {
                  text: i18n.t('activerecord.models.organization/profile.one'),
                  to: {
                    name: 'manager-organizations-profile-edit',
                    params: { organization_id: params.organization_id },
                  },
                },
              ],
            },
            children: [
              {
                path: 'edit',
                name: 'manager-organizations-profile-edit',
                component: asyncLoadRouter('manager/organizations/profile/Form'),
                meta: { breadcrumb: (i18n) => [{ text: i18n.t('shared.actions.edit') }] },
                props: { action: 'update' },
              },
            ],
          },
          {
            path: 'contact',
            component: asyncLoadRouter('manager/organizations/Contact'),
            redirect: (to) => {
              return {
                name: 'manager-organizations-contact-edit',
                params: { organization_id: to.params.organization_id },
              }
            },
            meta: {
              breadcrumb: (i18n, params) => [
                {
                  text: i18n.t('activerecord.models.contact.one'),
                  to: {
                    name: 'manager-organizations-contact-edit',
                    params: { organization_id: params.organization_id },
                  },
                },
              ],
            },
            children: [
              {
                path: 'edit',
                name: 'manager-organizations-contact-edit',
                component: asyncLoadRouter('manager/organizations/contact/Form'),
                meta: { breadcrumb: (i18n) => [{ text: i18n.t('shared.actions.edit') }] },
                props: { action: 'update' },
              },
            ],
          },
          {
            path: 'address',
            component: asyncLoadRouter('manager/organizations/Address'),
            redirect: (to) => {
              return {
                name: 'manager-organizations-address-edit',
                params: { organization_id: to.params.organization_id },
              }
            },
            meta: {
              breadcrumb: (i18n, params) => [
                {
                  text: i18n.t('activerecord.models.address.one'),
                  to: {
                    name: 'manager-organizations-address-edit',
                    params: { organization_id: params.organization_id },
                  },
                },
              ],
            },
            children: [
              {
                path: 'edit',
                name: 'manager-organizations-address-edit',
                component: asyncLoadRouter('manager/organizations/address/Form'),
                meta: { breadcrumb: (i18n) => [{ text: i18n.t('shared.actions.edit') }] },
                props: { action: 'update' },
              },
            ],
          },
          ...machines,
          ...categories,
          ...roles,
        ],
      },
    ],
  },
]

export default organizations
