export default class Account {
  static from(data) {
    try {
      return new Account(data)
    } catch (_) {
      return null
    }
  }
  constructor(data) {
    this.sub = data.id
    this.typ = data.type
    this.sid = data.attributes.sid
    this.loc = data.attributes.locale
    this.tiz = data.attributes.time_zone
    this.rol = data.attributes.roles_camelized
    this.pol = data.attributes.policies
  }
}
