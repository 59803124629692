const StorageFeatureDetectionKey = 'StorageFeatureDetectionKey'

export default class StorageFeatureDetection {
  static isEnabled(storageType) {
    try {
      window[storageType].setItem(StorageFeatureDetectionKey, 'feature detection check')
      window[storageType].getItem(StorageFeatureDetectionKey)
      window[storageType].removeItem(StorageFeatureDetectionKey)
      return true
    } catch (_) {
      return false
    }
  }
}
