import apiAccount from '@services/api/account'

export default {
  namespaced: true,
  actions: {
    getPanel(_, payload) {
      return apiAccount.panel(this.$http, payload)
    },
    getSwitchables(_, payload) {
      return apiAccount.switchables(this.$http, payload)
    },
  },
}
