import apiFirmwares from '@services/api/manager/firmwares'

export default {
  namespaced: true,
  actions: {
    getPanel(_, payload) {
      return apiFirmwares.panel(this.$http, payload)
    },
  },
}
